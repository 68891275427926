<template>
  <div>
    <ArrowMenu :menu="moduli"></ArrowMenu>
    <router-view
      v-if="default_lang || isNewCampaign"
      :key="$route.path"
      @update-company="setCampaignLanguages"
    />
  </div>
</template>

<script>
import ArrowMenu from "../components/ArrowMenu"
import AxiosService from "./../axiosServices/AxiosService"
import { mapGetters } from "vuex"
export default {
  components: {
    ArrowMenu,
  },
  data() {
    return {
      companyLanguages: [],
      default_lang: null,
    }
  },
  service: null,
  companyService: null,
  created() {
    this.service = new AxiosService("Campaign")
    this.companyService = new AxiosService("Company")
    this.loadCampaignLanguages()
  },
  methods: {
    loadCampaignLanguages() {
      if (!this.isNewCampaign) {
        this.service.readById(this.$route.params.campaignId).then((data) => {
          this.setCampaignLanguages(data.id_company)
        })
      }
    },
    setCampaignLanguages(id_company) {
      if (id_company) {
        this.companyService.readById(id_company).then((company) => {
          this.companyLanguages = this.languages.filter((x) =>
            company.lingue.includes(x.ID)
          )
          if (!this.companyLanguages.length) {
            this.companyLanguages = this.languages
          }
          this.default_lang = this.companyLanguages[0]
        })
      }
    },
  },
  computed: {
    isNewCampaign() {
      return this.$route.params.campaignId == null
    },
    moduli() {
      return [
        { title: "1. Details", link: "details" },

        {
          title: "2. Mission",
          link: "mission",
          disabled: this.isNewCampaign,
          locked: !this.modules["Mission"],
        },

        {
          title: "3. Kpis",
          link: "kpis",
          disabled: this.isNewCampaign,
          locked: !this.modules["Kpis"],
        },

        {
          title: "4. Notification",
          link: "notification",
          disabled: this.isNewCampaign,
          locked: !this.modules["Notification"],
        },
      ]
    },
    ...mapGetters(["modules"]),
    ...mapGetters(["languages"]),
  },
  watch: {
    isNewCampaign: function() {
      this.loadCampaignLanguages()
    },
  },
}
</script>
